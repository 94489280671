import React, { Component } from 'react'
import catMeme from '../images/meme.png'
import styles from '../styles'
import request, { request as gqlRequest, gql } from 'graphql-request'

class GenerateMeme extends Component {
    constructor(props) {
        super();
        this.state = {
            inputs: {},
            imageUrl: catMeme
        }
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    onSubmit() {
        console.log(this.state)
        const query = gql`mutation getPicture($text: String!) { createFunnyPicture(text: $text) {image, caption}}`;
        request('/graphql', query, { text: this.state.inputs.text || 'Add your funny caption!' })
            .then((data) => {
                console.log(data)

                this.setState({
                    imageUrl: `data:image/jpeg;base64,${encodeURIComponent(data.createFunnyPicture.image)}`
                })
            })
    }

    onChange(e) {
        this.setState({
            inputs: {
                ...this.state.inputs,
                [e.target.name]: e.target.value,
            }
        })
    }

    render() {
        return <div style={{
            width: '100%',
            textAlign: 'center'
        }}>
            <h1>Happy Birthday Lily!</h1>
            <p style={{
                fontSize: '1.3rem',
                marginTop: '20px',
                textAlign: 'center',
                padding: '15px'
            }}>
                This month our company cat Lily will celebrate her very first birthday!<br />
                To commemorate that special occasion we invite you to create and share your own funny picture of her.<br />
                On her birthday, we will reward the winner with a $15 amazon voucher. Have fun!
            </p>
            <img style={{
                marginTop: '20px',
                maxWidth: '90%',
                height: 'auto'
            }} src={this.state.imageUrl} />
            <div style={{
                marginTop: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <input onKeyDown={e => { if (e.key === 'Enter') this.onSubmit() }} name="text" onChange={this.onChange} style={{
                    maxWidth: '250px',
                    width: '100%',
                    fontSize: '1.3rem',
                    padding: '5px 10px',
                    textAlign: 'center'
                }} placeholder="Add your funny caption!" />
                <button onClick={this.onSubmit} style={{
                    padding: '10px 10px',
                    marginLeft: '10px',
                    border: '0px',
                    borderRadius: '4px',
                    background: styles.invictiPurple,
                    color: styles.invictiGreen
                }}>Send</button>
            </div>
        </div>
    }
}

export default GenerateMeme