import React, { Component } from 'react'
import styles from '../../styles'
import NavBarLink from './NavBarLink';

class NavBar extends Component {
    constructor() {
        super();
    }

    render() {
        return <div style={{
            display: 'flex',
            position: 'fixed',
            height: '50px',
            width: '100%',
            background: styles.invictiPurple,
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0px 20px',
            boxSizing: 'border-box'
        }}>
            <div style={{
                display: 'flex'
            }}>
                <NavBarLink href="/">Home</NavBarLink>
                <NavBarLink href="/order">Order</NavBarLink>
                <NavBarLink href="/seo">SEO Check</NavBarLink>
            </div>
            <div style={{
                display: 'flex'
            }}>
                <NavBarLink href="/about">About</NavBarLink>
                <NavBarLink href="/contact">Contact</NavBarLink>
            </div>
        </div>
    }
}

export default NavBar;