import React, { Component } from 'react';
import FormInput from '../components/Inputs/FormInput'
import FormSelection from '../components/Inputs/FormSelection';
import styles from '../styles';
import request, { gql } from 'graphql-request'

class Order extends Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            inputs: {},
            offer: null
        }
        this.submitHandler = this.submitHandler.bind(this)
        this.changeHandler = this.changeHandler.bind(this)
    }


    changeHandler(e) {
        this.setState({
            inputs: {
                ...this.state.inputs,
                [e.target.name]: e.target.value,
            }
        })
    }

    submitHandler(e) {
        e.preventDefault();
        const query = gql`query getWebsiteOffer($brandName: String!, $numberOfWebsites: Int!, $averageSize: String!, $emailAddress: String!) { getWebsiteOffer(brandName: $brandName, numberOfWebsites: $numberOfWebsites, averageSize: $averageSize, emailAddress: $emailAddress) {offer}}`;
        request('/graphql', query, {
            brandName: this.state.inputs.name || 'Example',
            numberOfWebsites: parseInt(this.state.inputs.amount) || 1,
            averageSize: this.state.inputs.size || 'Medium',
            emailAddress: this.state.inputs.email || 'test@example.com'
        })
            .then((data) => {
                console.log(data.getWebsiteOffer.offer)
                this.setState({
                    offer: data.getWebsiteOffer.offer
                })
            })
        console.log(this.state.inputs)
    }

    render() {
        return (<div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        }}>
            <div style={{
                marginBottom: '10px'
            }}>
                <h1>Place your Order!</h1>
            </div>
            <div style={{
                width: '350px',
                maxWidth: '100%',
                padding: '10px'
            }}>
                <form onSubmit={this.submitHandler}>
                    <FormInput onChange={this.changeHandler} type="text" name="name" placeholder="Brand Name" />
                    <FormInput onChange={this.changeHandler} style={{
                        marginTop: '15px'
                    }} type="number" name="amount" placeholder="# of websites" />
                    <FormSelection onChange={this.changeHandler} name="size" style={{ marginTop: '15px' }} description="Average size">
                        {["Small", "Medium", "Large"]}
                    </FormSelection>
                    <FormInput onChange={this.changeHandler} style={{
                        marginTop: '15px'
                    }} type="email" name="email" placeholder="Your Email Address" />
                    <button type="submit" style={{
                        width: '100%',
                        padding: '10px',
                        marginTop: '15px',
                        border: '0px',
                        background: styles.invictiPurple,
                        color: styles.invictiGreen,
                        cursor: 'pointer'
                    }}>
                        Get Offer
                    </button>
                </form>
                <div style={{
                    display: this.state.offer, //# || 'none',
                    width: '100%',
                    border: '1px solid rgb(220,220,220)',
                    marginTop: '20px',
                    padding: '15px'
                }}>
                    <pre>{this.state.offer}</pre>
                </div>
            </div>
        </div>)
    }
}

export default Order;