import React, { Component, createRef } from 'react';
import styles from '../styles'


class Contact extends Component {
    constructor(props) {
        super();
        this.nameRef = createRef();
        this.submitHandler = this.submitHandler.bind(this)
    }

    submitHandler(e) {
        e.preventDefault();
        const name = this.nameRef.current.value;
        document.location.href = `#${name}`
        document.location.reload();
    }

    render() {
        return document.location.hash ? (
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%'
            }}>
                <div style={{
                    width: '100%',
                    maxWidth: '500px',
                    textAlign: 'center'
                }} dangerouslySetInnerHTML={{ __html: `Thank you for your message, ${decodeURIComponent(document.location.hash.substring(1))}. We will come back to you shortly.` }}>

                </div>
            </div>
        ) : (
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%'
            }}>
                <div style={{
                    width: '100%',
                    maxWidth: '300px'
                }}>
                    <form onSubmit={this.submitHandler}>
                        <input ref={this.nameRef} style={{
                            border: '1px solid black',
                            padding: '10px 5px',
                            borderRadius: '4px',
                            width: '100%',
                            marginBottom: '15px'
                        }} type="text" name="name" placeholder='John Doe' />
                        <input style={{
                            border: '1px solid black',
                            padding: '10px 5px',
                            borderRadius: '4px',
                            width: '100%',
                            marginBottom: '15px'
                        }} type="text" name="mail" placeholder='test@example.com' />
                        <textarea style={{
                            border: '1px solid black',
                            padding: '10px 5px',
                            borderRadius: '4px',
                            width: '100%',
                            marginBottom: '15px'
                        }} placeholder="Message...">

                        </textarea>
                        <div style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}>
                            <button style={{
                                padding: '5px 10px',
                                background: styles.invictiPurple,
                                border: '0px',
                                borderRadius: '4px',
                                color: styles.invictiGreen
                            }} type="submit">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default Contact