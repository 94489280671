import React, { Component } from 'react';
import Logo from '../images/logo.png'
import styles from '../styles';
import angularWebsite from '../images/angular.png'
import aspWebsite from '../images/asp.png'
import phpWebsite from '../images/php.png'
import request, { request as gqlRequest, gql } from 'graphql-request'

class Landing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            images: {
                angularWebsite: null,
                phpWebsite: null,
                aspWebsite: null
            }
        }
    }


    componentDidMount() {
        // fetch a file from graphql. Use the graphql-request library to make a request to the server.
        const query = gql`query getImageFiles { angular: getFile(name: "angular.png") {content}, php : getFile(name:"php.png") {content}, asp: getFile(name: "asp.png") {content} }`;
        request('/graphql', query)
            .then((data) => {
                console.log(data)
                const images = this.state.images;
                this.setState({
                    images: {
                        ...images,
                        angularWebsite: `data:image/png;base64,${data.angular.content}`,
                        phpWebsite: `data:image/png;base64,${data.php.content}`,
                        aspWebsite: `data:image/png;base64,${data.asp.content}`
                    }
                })
            }).catch((err) => {
                console.log(err)
            })

    }

    render() {
        return <div style={{
            textAlign: 'center',
        }}>
            <h1 style={{
                marginBottom: '25px'
            }}>Quality Websites on a Budget</h1>
            <p style={{
                fontSize: '1.2rem',
                padding: '5px'
            }}>Hello and welcome to our website. We are a young team of developers and designers who create quality websites for a fair price.</p>
            <p style={{
                fontSize: '1.2rem',
                padding: '5px'
            }}>With an unmatched security track record, you will receive a secure website that looks good and represents your brand.</p>

            <div style={{
                width: '100%',
                boxSizing: 'border-box',
                padding: '20px 50px 30px',
                background: styles.invictiGrey,
                textAlign: 'center',
                marginTop: '25px'
            }}>
                <h1 style={{
                    marginBottom: '20px'
                }}>Our Portfolio</h1>
                <a href="http://angular.testsparker.com/home">
                    <img style={{
                        height: '200px',
                        width: 'auto',
                        padding: '0px 10px'
                    }} src={this.state.images.angularWebsite} />
                </a>
                <a href="http://php.testsparker.com/process.php?file=Generics/index.nsp">
                    <img style={{
                        height: '200px',
                        width: 'auto',
                        padding: '0px 10px'
                    }} src={this.state.images.phpWebsite} />
                </a>
                <a href="http://aspnet.testsparker.com/">
                    <img style={{
                        height: '200px',
                        width: 'auto',
                        padding: '0px 10px'
                    }} src={this.state.images.aspWebsite} />
                </a>
            </div>
        </div>
    }
}

export default Landing;