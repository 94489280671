import React, { Component } from 'react';

class FormInput extends Component {
    constructor(props) {
        super(props);
        const { style, ...restProps } = props;
        this.style = style;
        this.restProps = restProps;
    }

    render() {
        return (
            <input style={{
                padding: '4px 10px',
                width: '100%',
                fontSize: '1.2rem',
                ...this.props.style
            }} {...this.restProps}></input>
        )
    }
}

export default FormInput