import React, { Component } from 'react';

class FormSelection extends Component {
    constructor(props) {
        super(props);
        const { style, ...restProps } = props;
        this.style = style;
        this.restProps = restProps;
    }

    render() {
        return (
            <select style={{
                padding: '4px 10px',
                width: '100%',
                fontSize: '1.2rem',
                ...this.props.style
            }} {...this.restProps}>
                <option selected disabled>{this.props.description}</option>
                {this.props.children.map(value => {
                    return <option>{value}</option>
                })}
            </select>
        )
    }
}

export default FormSelection