import React, { Component } from 'react';
import styles from '../../styles'

class NavBarLink extends Component {
    constructor(props) {
        super(props);
        this.props = props;
    }
    render() {
        return (
            <a style={{
                padding: '4px 15px',
                //background: 'rgba(255,255,255,0.3)',
                height: '90%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '4px',
                boxSizing: 'border-box',
                fontWeight: 'bold',
                fontSize: '1.3rem',
                textDecoration: 'none',
                color: styles.invictiGreen,
                ...this.props.style
            }} href={this.props.href}>{this.props.children}</a>
        )
    }
}

export default NavBarLink;