import './App.css';
import { Routes, Route } from 'react-router-dom'
import Landing from './containers/Landing';
import NavBar from './components/NavBar';
import GenerateMeme from './containers/GenerateMeme'
import Order from './containers/Order'
import Contact from './containers/Contact'
import styles from './styles';
import InvictiLogo from './images/Invicti.png'
import Seo from './containers/Seo'
import PageNotFound from './containers/PageNotFound'

function App() {
  return (
    <>
      <NavBar />
      <div style={{
        paddingTop: '50px'
      }}>
        <div style={{
          width: '100%',
          background: 'moccasin',
          display: 'flex',
          justifyContent: 'center',
          padding: '10px 0px'
        }}><a href="/lily">🎉Celebrate With Us!🎉</a></div>
        <div style={{
          marginTop: '20px'
        }}>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/lily" element={<GenerateMeme />} />
            <Route path="/order" element={<Order />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/seo" element={<Seo />} />

          </Routes>
        </div>
      </div>
    </>
  );
}

export default App;
