import React, { Component } from 'react';
import styles from '../styles';
import request, { gql } from 'graphql-request'

class Seo extends Component {
    constructor(props) {
        super()
        this.props = props;
        this.state = {
            seoScore: null
        }
        this.urlRef = React.createRef();
        this.submitHandler = this.submitHandler.bind(this)
    }

    submitHandler(e) {
        e.preventDefault();
        const url = this.urlRef.current.value
        const query = gql`query getWebsiteSeoScore($url: String!) { getWebsiteSeoScore(url: $url) {seoScore, body}}`;
        request('/graphql', query, {
            url
        })
            .then((data) => {
                console.log(data)
                this.setState({
                    seoScore: data.getWebsiteSeoScore.seoScore
                })
            })
    }

    render() {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}>
                <h1 style={{
                    marginBottom: '10px'
                }}>SEO Check</h1>
                <div style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <p>Get you SEO score instantly and find out how well your website fares against your competitors</p>
                    <p style={{fontSize: "0.6rem"}}>Please note: For technical reasons this feature is restricted to http://testinvicti.com</p>
                    <form onSubmit={this.submitHandler}>
                        <input ref={this.urlRef} name="url" type="text" style={{
                            border: '1px solid rgb(220, 220, 220)',
                            padding: '10px 5px',
                            borderRadius: '4px',
                            borderTopRightRadius: '0px',
                            borderBottomRightRadius: '0px',
                            width: '200px',
                            outline: 'none',
                            margin: '35px 0px 10px 0px'
                        }} placeholder="Enter URL" />
                        <button style={{
                            border: '0px',
                            padding: '11px 5px',
                            borderRadius: '4px',
                            borderTopLeftRadius: '0px',
                            borderBottomLeftRadius: '0px',
                            width: '',
                            outline: 'none',
                            margin: '35px 0px 10px 0px',
                            background: styles.invictiPurple,
                            color: styles.invictiGreen,
                        }} type="submit">Get SEO Score</button>
                    </form>
                    <div style={{
                        marginTop: '25px',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'stretch'
                    }}>
                        <div style={{
                            height: '10px',
                            background: this.state.seoScore
                                ? 'red'
                                : 'lightgrey',
                            borderTopLeftRadius: '4px',
                            borderBottomLeftRadius: '4px',
                            marginRight: '1px',
                            width: '100%'
                        }}></div>
                        <div style={{
                            height: '10px',
                            background: this.state.seoScore && this.state.seoScore > 33
                                ? 'orange'
                                : 'lightgrey',
                            width: '100%',
                            marginRight: '1px',
                        }}></div>
                        <div style={{
                            height: '10px',
                            width: '100%',
                            background: 'lightgrey',
                            borderTopRightRadius: '4px',
                            borderBottomRightRadius: '4px',
                            marginRight: '1px',
                        }}></div>
                    </div>
                </div>
                <div style={{
                    marginTop: '35px',
                    display: this.state.seoScore
                        ? 'flex'
                        : 'none',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}>
                    <h2 style={{
                        fontSize: '1.3rem',
                    }}>Your SEO score</h2>
                    <h3 style={{
                        fontSize: '3rem',
                        margin: '0px',
                        color: this.state.seoScore < 33
                            ? 'red'
                            : 'orange'
                    }}>{this.state.seoScore}</h3>
                    <p style={{
                        fontSize: '1.3rem',
                    }}>{this.state.seoScore < 33
                        ? 'Your SEO score is low. There is opportunity to improve it!'
                        : 'Your SEO score is good. But it could be better!'}</p>
                    <p style={{
                        marginTop: '20px'
                    }}>We can help you get better search results and more customers! Just <a href="/contact">contact</a> us and we will make it happen.</p>
                </div>
            </div >
        );
    }
}

export default Seo;
